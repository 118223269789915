import React from "react"
import { useTranslation } from "react-i18next"
import CampaignSlideOne from "../components/campaignSlides/campaignSlideOne"
import CampaignSlideThree from "../components/campaignSlides/campaignSlideThree"
import CampaignSlideTwo from "../components/campaignSlides/campaignSlideTwo"
import Layout from "../components/layout"
import Seo from "../components/seo"

const CampaignPage = ({ pageContext: { originalPath } }) => {
  const { t } = useTranslation()

  return (
    <Layout title={t("campaign")} originalPath={originalPath}>
      <Seo title={t("campaign")} />
      <CampaignSlideOne />
      <CampaignSlideTwo />
      <CampaignSlideThree />
    </Layout>
  )
}

export default CampaignPage