import { Box, Button, Grid, Paper, Typography } from "@mui/material"
import { blueGrey } from "@mui/material/colors"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import SectionWrapper from "../sectionWrapper"

const campaignBoxesData = [
  {
    overline: "#techforequality",
    detail: (
      <Typography>
        <strong>Media Matters for Democracy</strong> explores the intersection
        of gender and technology in Pakistan. They highlight critical issues in
        relation to expression and space-making, access, and privacy and
        surveillance on the internet through their work.
      </Typography>
    ),
    btnLabel: "Explore their work",
    link: "https://twitter.com/mmfd_Pak/status/1405130564378804225",
    image: "mmfd",
  },
  {
    overline: "#techforequality",
    detail: (
      <Typography>
        <strong>IT for Change</strong>'s Bot Populi explores feminist
        imaginations of social media with scholars, activists and leaders from
        the Global South. How does the intersectionality of gender identities
        affect the way we experience social media? What does feminist social
        media look like? What changes in platform governance and alternative
        techno-design can help us realize feminist visions of social media?
      </Typography>
    ),
    btnLabel: "Listen to the podcast",
    link: "https://botpopuli.net/feminist-digital-futures",
    image: "botpopuli",
  },
  {
    overline: "#techforequality",
    detail: (
      <Typography>
        <strong>Minas Programam</strong> is a collective project led by young
        women of color to advocate for and activate initiatives aimed at
        promoting programming skills and other digital competencies and
        abilities for young girls in Brazil.
      </Typography>
    ),
    btnLabel: "Explore their work",
    link: "https://minasprogramam.com ",
    image: "minas-programam",
  },
  {
    overline: "#techforequality",
    detail: (
      <Typography>
        <strong>Girl Effect</strong>’s peer to peer digital research tool is
        bridging the gender data gap by unlocking open and honest conversations
        with girls, providing unique insights which would otherwise be lost.
        #HearHerVoice
      </Typography>
    ),
    btnLabel: "Explore more",
    link: "https://voices.girleffect.org/",
    image: "girl-effect",
  },
  {
    overline: "#techforequality",
    detail: (
      <Typography>
        <strong>World Pulse</strong> explores through the #SheTransformsTech
        research report what grassroots women and gender-diverse individuals
        from 60+ countries think individuals, policymakers, and tech companies
        must do to make tech equitable for all.
      </Typography>
    ),
    btnLabel: "Read the report",
    link: "https://impact.worldpulse.com/downloads/SheTransformsTech",
    image: "worldpulse",
  },
  {
    overline: "#techtrailblazers",
    detail: (
      <Typography>
        <strong>UNICEF</strong> and Action Coalition on Technology and
        Innovation leaders collectively commit to knowing more about girls and
        young women’s digital realities globally, investing in THEIR learning
        solutions designed for THEIR digital world. 
      </Typography>
    ),
    btnLabel: "Amplify their voices",
    link: "https://www.instagram.com/ar/469884944101225/",
    image: "amplify",
  },
  {
    overline: "algorithm = equality",
    detail: (
      <Typography>
        The Algorithm for Gender Equality is a campaign led by the{" "}
        <strong>Ministry for Foreign Affairs of Finland</strong> aimed at
        promoting gender equality in and through technology. Its goals are to
        activate discussion on gender equality in the technology sector, to
        promote openness in the field and make it more attractive, and to
        inspire girls and women to pursue careers in technology.
      </Typography>
    ),
    btnLabel: "Find out more",
    link: "https://um.fi/equaltech",
    image: "equaltech",
  },
]

const CampaignSlideThree = () => {
  const {
    allFile: { nodes: images },
  } = useStaticQuery(
    graphql`
      {
        allFile(filter: { relativeDirectory: { eq: "campaign" } }) {
          nodes {
            name
            childImageSharp {
              gatsbyImageData(aspectRatio: 1.333, placeholder: BLURRED)
            }
          }
        }
      }
    `
  )

  return (
    <SectionWrapper light>
      <Box pt={2}>
        <Grid container>
          {campaignBoxesData.map(
            ({ overline, detail, image, btnLabel, link }, index) => (
              <Grid item xs={12} key={index}>
                <Paper
                  variant="outlined"
                  style={{ backgroundColor: blueGrey[50] }}
                >
                  <Grid container>
                    <Grid item xs={12} md={4} style={{ fontSize: 0 }}>
                      <GatsbyImage
                        image={getImage(
                          images.find(element => element.name === image)
                        )}
                        alt={image}
                      />
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Box
                        p={2}
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                        height={1}
                      >
                        <Box>
                          <Typography
                            variant="overline"
                            color="textSecondary"
                            gutterBottom
                          >
                            {overline}
                          </Typography>
                          {detail}
                        </Box>
                        <Box>
                          <Button
                            href={link}
                            target="_blank"
                            rel="noreferrer"
                            endIcon={<ArrowForwardIcon />}
                          >
                            {btnLabel}
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            )
          )}
        </Grid>
      </Box>
    </SectionWrapper>
  )
}

export default CampaignSlideThree