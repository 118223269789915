import { Typography } from "@mui/material"
import React from "react"
import SectionWrapper from "../sectionWrapper"

const Highlight = ({ children }) => (
  <Typography variant="body2" component="span" color="primary">
    {children}
  </Typography>
)

const CampaignSlideTwo = () => {
  return (
    <SectionWrapper>
      <Typography variant="body2">
        How do we bring the voices calling for 
        <Highlight>equal digital rights</Highlight> that need to be heard to the
        surface of the world wide web? 
      </Typography>
      <Typography variant="body2" style={{ paddingTop: 40 }}>
        By being consistent, collective and diverse.
      </Typography>
      <Typography variant="body2" style={{ paddingTop: 40 }}>
        <strong>#TechforEquality</strong> is a digital moment showcasing the
        power of intersectional and grassroots voices to generate commitments
        to make technology gender equal. 
      </Typography>
    </SectionWrapper>
  )
}

export default CampaignSlideTwo