import { Typography } from "@mui/material"
import React from "react"
import SectionWrapper from "../sectionWrapper"

const CampaignSlideOne = () => {
  return (
    <SectionWrapper light>
      <Typography variant="h2" component="p">
        We are urging the world to look at technology as one of the key areas in
        which achieving gender equality can transform our societies.
      </Typography>
    </SectionWrapper>
  )
}

export default CampaignSlideOne